import React, { useEffect, useState, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import firebase from './firebase';
import useElementItems from './hooks/useElementItems';
import ToppingListItem from './ToppingListItem';
import { StoreContainer } from './store';
import ShopsContext from './ShopsContext';

function ToppingList() {
  interface IToppingPlan {
    toppingPlan: firebase.firestore.DocumentData;
    elementItem: firebase.firestore.DocumentData;
  }

  const storeContainer = StoreContainer.useContainer();
  const [summary, setSummary] = useState<any>();
  const [plans, setPlans] = useState<IToppingPlan[]>();
  const [closingTime, setClosingTime] = useState<Date>();
  const elementItems = useElementItems();
  const [setupInAdvanceItems, setSetupInAdvanceItems] =
    useState<Array<{ elementItemId: string; name: string; size: number }>>();

  const { currentShop } = useContext(ShopsContext);
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    const sortPlans = (a: IToppingPlan, b: IToppingPlan) => {
      return a.elementItem.data().kitchen_order - b.elementItem.data().kitchen_order;
    };

    if (elementItems) {
      const date = dayjs().tz('Asia/Tokyo');
      firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('slots')
        .where('time_until', '<', date.endOf('day').toDate())
        .orderBy('time_until', 'desc')
        .limit(1)
        .get()
        .then((query) => {
          console.log('ToppingList slot');
          const doc = query.docs[0];
          if (doc) {
            setClosingTime(doc.data().time_until.toDate());
          } else {
            setClosingTime(new Date());
          }
        });

      const unregisterToppingsPlans = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('topping_plans')
        .where('available_for_kitchen', '==', true)
        .onSnapshot((snap) => {
          console.log('onSnapshot topping_plans');
          const toppingPlans: IToppingPlan[] = [];

          snap.forEach((doc) => {
            const toppingPlan = doc.data();
            const elementItem = elementItems.find((e) => toppingPlan.element_item_id === e.id);
            if (elementItem) {
              toppingPlans.push({ toppingPlan, elementItem });
            }
          });

          const sortedPlans = toppingPlans.sort(sortPlans);
          setPlans(sortedPlans);
        });

      return () => {
        console.log('unregister ToppingList');
        unregisterToppingsPlans();
      };
    }

    return () => {};
  }, [storeContainer.shopId, elementItems]);

  useEffect(() => {
    if (elementItems) {
      const date = dayjs().tz('Asia/Tokyo');

      const unregisterOrdersObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('orders_for_kitchen')
        .where('pickup_at', '>=', date.startOf('day').toDate())
        .where('pickup_at', '<', date.endOf('day').toDate())
        .onSnapshot((snap) => {
          console.log('onSnapshot ToppingList');
          let fromNowOn = 0;
          let count = 0;

          snap.forEach((docSnapshot) => {
            const order = docSnapshot.data();
            if (order.status !== 'canceled') {
              count += 1;
              if (!order.cooked_at) {
                fromNowOn += 1;
              }
            }
          });
          setSummary([count, fromNowOn]);
        });

      return () => {
        unregisterOrdersObserver();
      };
    }

    return () => {};
  }, [storeContainer.shopId, elementItems]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => prev + 1);
    }, 60 * 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (elementItems) {
      const date = dayjs().tz('Asia/Tokyo');
      const setupInAdvanceElementItemIds = elementItems
        .filter((elementItem) => elementItem.data()!.setup_in_advance)
        .map((elementItem) => elementItem.data()!.id);

      const unregisterOrdersObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('orders_for_kitchen')
        .where('status', 'in', ['ordered', 'cooking'])
        .where('planned_cooking_at', '<=', date.add(30, 'minutes').toDate())
        .onSnapshot((snap) => {
          console.log('onSnapshot ToppingList');
          const setupInAdvance: Array<{ elementItemId: string; name: string; size: number }> = [];

          snap.forEach((docSnapshot) => {
            const order = docSnapshot.data();
            const components = order.item.components.filter(
              (component) => component.id !== 'topping' && component.id !== 'curry_mini',
            );
            components
              .map((component) => {
                return component.elements.filter(
                  (element) => element.quantity > 0 && setupInAdvanceElementItemIds.includes(element.item_id),
                );
              })
              .flat()
              .forEach((element) => {
                setupInAdvance.push({
                  elementItemId: element.item_id,
                  name: element.name,
                  size: element.size * element.quantity,
                });
              });
          });
          setSetupInAdvanceItems(setupInAdvance);
        });

      return () => {
        unregisterOrdersObserver();
      };
    }

    return () => {};
  }, [storeContainer.shopId, elementItems, counter]);

  const renderSetupInAdvanceItems = () => {
    if (!setupInAdvanceItems || !elementItems || !plans) {
      return <></>;
    }

    setupInAdvanceItems.sort((a, b) => {
      const aOrder = elementItems.find((e) => e.id === a.elementItemId)?.data()!.kitchen_order;
      const bOrder = elementItems.find((e) => e.id === b.elementItemId)?.data()!.kitchen_order;

      if (aOrder && bOrder && aOrder !== bOrder) {
        return aOrder - bOrder;
      }

      return a.size - b.size;
    });

    const groupBy: {
      [key: string]: {
        value: { elementItemId: string; name: string; size: number };
        count: number;
      };
    } = {};

    for (const item of setupInAdvanceItems) {
      const key = JSON.stringify(item);
      if (!groupBy[key]) {
        groupBy[key] = { value: item, count: 0 };
      }
      groupBy[key].count += 1;
    }

    const items = Object.values(groupBy).map((item) => (
      <div className="row justify-content-start">
        <div className="col-2">
          {elementItems.find((elementItem) => elementItem.id === item.value.elementItemId)?.data()!.kitchen_name ||
            '不明'}
        </div>
        <div className="col-2">
          {item.value.size} ({item.value.name})
        </div>
        <div className="col-1">{item.count} 個</div>
      </div>
    ));

    return (
      <>
        <div className="container ">{items.length > 0 ? items : <div>なし</div>}</div>
      </>
    );
  };

  const reportStocks = () => {
    const emptyDiv = () => <div />;

    const options = {
      title: '在庫/廃棄(予定)数報告',
      message: '',
      buttons: [
        {
          label: '報告',
          onClick: () => {
            const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${storeContainer.shopId}/report_stocks/`;
            const auth = firebase.auth();
            setPosting(true);

            auth.currentUser!.getIdToken().then((token) => {
              fetch(apiEndPoint, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
              })
                .then(async (response) => {
                  const responseJson = await response.json();
                  if (response.status !== 200) {
                    window.alert(responseJson.error.message);
                  }
                  setPosting(false);
                })
                .catch(() => {
                  setPosting(false);
                });
            });
          },
        },
        {
          label: 'キャンセル',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const lastStockReportedAt = currentShop?.data()!.stock_reported_at
    ? dayjs(currentShop.data()!.stock_reported_at.toDate()).tz('Asia/Tokyo')
    : null;

  return (
    <div>
      {elementItems && summary && plans && closingTime ? (
        <>
          <div className="row mb-4">
            <div className="col">
              <div className="card shadow">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col">
                      <h5 className="mb-1">お渡し済 / 注文合計 🍛</h5>
                    </div>
                    <div className="col-auto">
                      <h5 className="m-0">
                        <strong>
                          <big>{summary[0] - summary[1]}</big>
                        </strong>
                        <small> / </small>
                        <strong>
                          <big>{summary[0]}</big>
                        </strong>
                        <small> 食</small>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-right">
                      これから
                      <strong>
                        <big>{summary[1]}</big>
                      </strong>
                      食
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="card shadow">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col">
                      <h5 className="mb-1">これから30分以内の注文</h5>
                      {setupInAdvanceItems && renderSetupInAdvanceItems()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card shadow">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title col-2">在庫状況</h5>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          reportStocks();
                        }}
                      >
                        在庫廃棄報告
                      </button>
                      （最終報告:{' '}
                      {lastStockReportedAt && lastStockReportedAt.isAfter(dayjs().tz('Asia/Tokyo').startOf('day')) ? (
                        <>
                          {dayjs(currentShop?.data()!.stock_reported_at.toDate())
                            .tz('Asia/Tokyo')
                            .format('YYYY-MM-DD HH:mm')}
                        </>
                      ) : (
                        <span className="badge badge-warning">本日未報告</span>
                      )}
                      ）
                    </div>
                    <span className="col-auto">
                      <a
                        href="https://docs.google.com/spreadsheets/d/1V_ur_n6jHRx9hXZNz4O6bQvD5RY7xaPOnMSXmSt82nk/edit#gid=1182396953&range=B2:G2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        本日の惣菜期限
                      </a>
                    </span>
                  </div>

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: '15%' }}>名前</th>
                        <th>状況</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plans.map((plan) => (
                        <ToppingListItem
                          key={`topping-${plan.elementItem.id}`}
                          elementItem={plan.elementItem}
                          toppingPlan={plan.toppingPlan}
                          closingTime={closingTime!}
                          inProgress={setPosting}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        'loading'
      )}

      <WindmillSpinnerOverlay loading={posting} message="更新中" />
    </div>
  );
}

export default ToppingList;
