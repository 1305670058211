const salesChannels = [
  { value: 'takeout', name: 'テイクアウト', shortName: 'テ', orderApp: true },
  { value: 'office_delivery', name: '法人デリバリー', shortName: '法', orderApp: true },
  { value: 'catering', name: '社食', shortName: '社', orderApp: true },
  { value: 'stand', name: 'スタンド', shortName: 'ス', orderApp: true },
  { value: 'eatin', name: 'イートイン', shortName: 'イ', orderApp: true },
  { value: 'delivery_provider', name: '外部デリバリー', shortName: '外', orderApp: false },
];

export default salesChannels;
