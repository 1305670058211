import React, { useContext, useEffect, useState } from 'react';
import firebase from './firebase';
import KitchenWarnings from './KitchenWarnings';
import Order from './Order';
import { StoreContainer } from './store';
import ShopsContext from './ShopsContext';
import dayjs from './dayjs';

function LineOrders(props: { line: number; kitchenLayoutSnapshot: firebase.firestore.DocumentData | null }) {
  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const { currentShop } = useContext(ShopsContext);

  useEffect(() => {
    // 調理優先順位: https://docs.google.com/spreadsheets/d/1vQd316jAAV4HVsGV3svXwQsiA59GU1mF6-AZm2J7lak/edit?gid=0#gid=0
    const deadline = (
      doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      now: dayjs.Dayjs,
    ) => {
      return doc.data().pickup_now
        ? dayjs(doc.data().ordered_at.toDate()).add(5, 'minutes').unix()
        : doc.data().delivery_departure_due_time
        ? dayjs(doc.data().delivery_departure_due_time.toDate()).add(-10, 'minutes').unix()
        : doc.data().estimated_at
        ? dayjs(doc.data().estimated_at.toDate()).add(-3, 'minutes').unix()
        : now.unix();
    };

    const sortOrders = (
      a: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      b: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    ) => {
      // 盛り付け開始したものは常に最初に出す(割り込ませない)
      if (a.data().kitchen_cooking_at && !b.data().kitchen_cooking_at) {
        return -1;
      }
      if (!a.data().kitchen_cooking_at && b.data().kitchen_cooking_at) {
        return 1;
      }
      if (a.data().kitchen_cooking_at && b.data().kitchen_cooking_at) {
        return a.data().kitchen_cooking_at.toDate() - b.data().kitchen_cooking_at.toDate();
      }

      const now = dayjs();
      const deadlineA = deadline(a, now);
      const deadlineB = deadline(b, now);

      return deadlineA - deadlineB;
    };

    let query = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'cooking');

    if (props.line === -1) {
      // -1は全てなのでライン指定しない
    } else {
      query = query.where('line2', '==', props.line);
    }

    const unregisterOrdersObserver = query
      .orderBy('planned_cooking_at', 'asc')
      .orderBy('pickup_at', 'asc')
      .orderBy('estimated_at', 'asc')
      .orderBy('number', 'asc')
      .orderBy('nth_curry_in_this_order', 'asc')
      .onSnapshot((snap) => {
        console.log('onSnapshot LineOrders');

        const reordered = snap.docs
          .filter((doc) => !(currentShop!.data()!.enabled_pickup_now_line && doc.data().pickup_now))
          .sort(sortOrders);

        setOrders(reordered);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.line, storeContainer.shopId, currentShop]);

  return (
    <>
      {orders === undefined ? (
        <div className="container-fluid h-100">
          <div className="row">
            <div className="col-12 align-self-center text-center">
              <h5>loading..</h5>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(orders).length === 0 ? (
            <div className="container-fluid h-100">
              <div className="row p-3">
                <div className="col-12 align-self-center text-center">
                  <h2>待機中</h2>
                </div>
              </div>
              <KitchenWarnings />
            </div>
          ) : (
            <>
              <div className="scrollable-orders">
                {orders.map((tmp) => (
                  <Order
                    key={tmp.id}
                    kitchenOrderId={tmp.id}
                    order={tmp.data()}
                    line={props.line}
                    kitchenLayoutSnapshot={props.kitchenLayoutSnapshot}
                    hideWhenDone
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default LineOrders;
