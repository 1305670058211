/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { v4 as uuidv4 } from 'uuid';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import ShopsContext from './ShopsContext';
import ShopSlotPatternDetail from './ShopSlotPatternDetail';
import useAnycarryAddresses from './hooks/useAnycarryAddress';

function SummaryForm(props: {
  shopId: string;
  slotPattern: firebase.firestore.DocumentSnapshot;
  childShops: Array<{ id: string; data: firebase.firestore.DocumentData }>;
}) {
  const {
    register,
    unregister,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [orderExists, setOrderExists] = useState<boolean>(true);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [posting, setPosting] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [deliveryGroups, setDeliveryGroups] = useState<
    Array<{
      id: string;
      name: string;
      limitCurriesPerSlot: number | null;
      position: number;
      anycarryAddressId: string | null;
      anycarryNote: string;
      deliveryProvider: 'self' | 'wolt' | 'anycarry';
    }>
  >();
  const { shops } = useContext(ShopsContext);

  const shop = shops[props.shopId];

  useEffect(() => {
    setDeliveryGroups(
      props.slotPattern.data()!.delivery_groups
        ? Object.keys(props.slotPattern.data()!.delivery_groups)
            .map((deliveryGroupId) => {
              const data = props.slotPattern.data()!.delivery_groups[deliveryGroupId];
              return {
                id: deliveryGroupId,
                name: data.name,
                limitCurriesPerSlot: data.limit_curries_per_slot,
                position: data.position,
                anycarryAddressId: data.anycarry_address_id ?? null,
                anycarryNote: data.anycarry_note ?? '',
                deliveryProvider: data.delivery_provider ?? 'self',
              };
            })
            .sort((a, b) => a.position - b.position)
        : [],
    );
  }, [props.slotPattern]);

  useEffect(() => {
    const unregisterShopObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.shopId)
      .collection('cooking_slots')
      .where('slot_pattern_id', '==', props.slotPattern.id)
      .where('valid', '==', true)
      .where('order_id', '!=', null)
      .limit(1)
      .onSnapshot((docs) => {
        console.log('onSnapshot SummaryForm');
        setOrderExists(docs.size > 0);
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.shopId, props.slotPattern]);

  const emptyDiv = () => <div />;

  const onSubmit = (data) => {
    const options = {
      title: '店舗スロットパターン情報の更新を行います',
      message: '変更は直ちに反映されます',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const submit = (argData) => {
    setPosting(true);
    const data = { ...argData };

    if (data.settings) {
      for (const id of Object.keys(data.settings)) {
        data.settings[id].delivery_priority = parseInt(data.settings[id].delivery_priority, 10) || 1;
      }
    }

    if (data.delivery_groups) {
      for (const id of Object.keys(data.delivery_groups)) {
        data.delivery_groups[id].limit_curries_per_slot =
          parseInt(data.delivery_groups[id].limit_curries_per_slot, 10) || null;
        data.delivery_groups[id].position = parseInt(data.delivery_groups[id].position, 10);
        if (data.delivery_groups[id].anycarry_address_id === '') {
          data.delivery_groups[id].anycarry_address_id = null;
        }
      }
    }

    const deliveryInstructions = data.delivery_instructions ? { ...data.delivery_instructions } : {};
    delete data.delivery_instructions;

    const batch = firebase.firestore().batch();

    batch.update(props.slotPattern.ref, data);

    for (const key of Object.keys(deliveryInstructions)) {
      batch.update(firebase.firestore().collection('shops').doc(key), {
        delivery_instruction: deliveryInstructions[key],
      });
    }

    batch
      .commit()
      .then(() => {
        setMessageSuccess('更新完了');
        setPosting(false);
      })
      .catch((error) => {
        setMessageError(`更新に失敗しました${error}`);
        setPosting(false);
      });
  };

  const addDeliveryGroup = () => {
    setDeliveryGroups((prev) => [
      ...prev!,
      {
        id: uuidv4(),
        name: '',
        limitCurriesPerSlot: null,
        position: prev!.length + 1,
        anycarryAddressId: null,
        anycarryNote: '',
        deliveryProvider: 'anycarry',
      },
    ]);
  };

  const deleteDeliveryGroup = (deleteId: string) => {
    unregister(`delivery_groups[${deleteId}]`);
    setDeliveryGroups((prev) => prev!.filter((deliveryGroup) => deliveryGroup.id !== deleteId));
  };

  const anycarryAddresses = useAnycarryAddresses();

  return (
    <>
      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      <form id="slot-pattern-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputSlotPatternLabel">
            パターン名
          </label>
          <div className="col-sm-8">
            <input
              defaultValue={props.slotPattern.data()!.label}
              {...register('label', { required: true })}
              id="inputSlotPatternLabel"
              type="text"
              size={50}
            />
            <small className="text-danger">{errors.label && '必須です'}</small>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputDeliveryPriority">
            法人デリバリー優先順位 (同時間の場合、数字が小さい方を先に配達する)
          </label>

          <div className="col">
            {props.childShops
              .filter((childShop) => {
                return childShop.data.batch_delivery;
              })
              .map((childShop) => {
                return (
                  <div className="row" key={childShop.id}>
                    <div className="col-sm-4">{childShop.data.short_name}</div>

                    <div className="col-sm-4">
                      <input
                        id="inputDeliveryPriority"
                        defaultValue={props.slotPattern.data()!.settings[childShop.id]?.delivery_priority || 1}
                        type="number"
                        size={50}
                        {...register(`settings[${childShop.id}].delivery_priority`, {})}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {deliveryGroups && anycarryAddresses && (
          <div className="form-group row">
            <label className="col-sm-2 col-form-label" htmlFor="deliveryGroup">
              デリバリーグループ
            </label>

            <div className="col">
              <div className="row">
                <div className="col">
                  <button type="button" className="btn btn-sm btn-dark" onClick={addDeliveryGroup}>
                    デリバリーグループ追加
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-2">名前</div>
                <div className="col-2">スロット毎食数上限</div>
                <div className="col-3">配達方法</div>
                <div className="col-3" />
              </div>
              {deliveryGroups.map((deliveryGroup) => (
                <>
                  <div className="row" key={deliveryGroup.id}>
                    <div className="col-2">
                      <input
                        defaultValue={deliveryGroup.name}
                        type="text"
                        size={30}
                        {...register(`delivery_groups[${deliveryGroup.id}].name`, {})}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        defaultValue={deliveryGroup.limitCurriesPerSlot || ''}
                        type="number"
                        {...register(`delivery_groups[${deliveryGroup.id}].limit_curries_per_slot`, {})}
                      />
                    </div>
                    <div className="col-3">
                      {shop.data()!.anycarry_address_id ? (
                        <span>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              defaultChecked={deliveryGroup.deliveryProvider === 'self'}
                              {...register(`delivery_groups[${deliveryGroup.id}].delivery_provider`, {})}
                              type="radio"
                              value="self"
                              id={`form-check-input-${deliveryGroup.id}-wolt`}
                            />

                            <label className="form-check-label" htmlFor={`form-check-input-${deliveryGroup.id}-wolt`}>
                              自社
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              defaultChecked={deliveryGroup.deliveryProvider === 'anycarry'}
                              {...register(`delivery_groups[${deliveryGroup.id}].delivery_provider`, {})}
                              type="radio"
                              value="anycarry"
                              id={`form-check-input-${deliveryGroup.id}-anycarry`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`form-check-input-${deliveryGroup.id}-anycarry`}
                            >
                              エニキャリ
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              defaultChecked={deliveryGroup.deliveryProvider === 'wolt'}
                              {...register(`delivery_groups[${deliveryGroup.id}].delivery_provider`, {})}
                              type="radio"
                              value="wolt"
                              id={`form-check-input-${deliveryGroup.id}-wolt`}
                            />

                            <label className="form-check-label" htmlFor={`form-check-input-${deliveryGroup.id}-wolt`}>
                              Wolt
                            </label>
                          </div>
                        </span>
                      ) : (
                        <span className="mx-2">親店舗エニキャリ未設定</span>
                      )}
                    </div>
                    <div className="col-3">
                      {shop.data()!.anycarry_address_id ? (
                        <select
                          className="form-control form-control-sm"
                          defaultValue={deliveryGroup.anycarryAddressId || ''}
                          {...register(`delivery_groups[${deliveryGroup.id}].anycarry_address_id`, {})}
                        >
                          <option value="">-</option>
                          {anycarryAddresses.map((anycarryAddress) => (
                            <option key={anycarryAddress.id} value={anycarryAddress.id}>
                              {anycarryAddress.data().name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span className="mx-2">親店舗エニキャリ未設定</span>
                      )}
                    </div>
                    <div className="col-1">
                      <input
                        defaultValue={deliveryGroup.position}
                        type="hidden"
                        {...register(`delivery_groups[${deliveryGroup.id}].position`)}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={() => {
                          deleteDeliveryGroup(deliveryGroup.id);
                        }}
                      >
                        削除
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}

        {deliveryGroups && (
          <div className="form-group row">
            <label className="col-sm-2 col-form-label" htmlFor="deliveryGroup" />

            <div className="col">
              {props.childShops
                .filter((childShop) => {
                  return childShop.data.batch_delivery;
                })
                .map((childShop) => {
                  return (
                    <div className="row" key={childShop.id}>
                      <div className="col-sm-4">{childShop.data.short_name}</div>
                      <div className="col-sm-8">
                        <select
                          className="form-select form-select-sm"
                          defaultValue={props.slotPattern.data()!.settings[childShop.id]?.delivery_group_id}
                          {...register(`settings[${childShop.id}].delivery_group_id`, {})}
                        >
                          <option value="">未選択</option>
                          {deliveryGroups.map((deliveryGroup) => (
                            <>
                              <option value={deliveryGroup.id}>{deliveryGroup.name}</option>
                            </>
                          ))}
                        </select>{' '}
                        <input
                          defaultValue={props.slotPattern.data()!.settings[childShop.id]?.anycarry_note || ''}
                          placeholder="エニキャリ備考(階数等)"
                          type="text"
                          {...register(`settings[${childShop.id}].anycarry_note`, {
                            validate: {
                              require: (value) => {
                                return !value &&
                                  getValues(
                                    `delivery_groups[${getValues(
                                      `settings[${childShop.id}].delivery_group_id`,
                                    )}].delivery_provider`,
                                  ) === 'anycarry'
                                  ? '必須です'
                                  : true;
                              },
                            },
                          })}
                        />
                        <small className="text-danger">
                          {errors.settings &&
                            errors.settings[childShop.id] &&
                            errors.settings[childShop.id].anycarry_note &&
                            errors.settings[childShop.id].anycarry_note.message}
                        </small>
                        <br />
                        <textarea
                          id="deliveryInstruction"
                          defaultValue={childShop.data.delivery_instruction}
                          placeholder="配達注意事項 (注: パターン毎ではなく店舗/オフィス単位での設定)"
                          rows={4}
                          cols={60}
                          {...register(`delivery_instructions[${childShop.id}]`, {})}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <input type="submit" className="btn btn-primary" value="更新" />
      </form>
      <WindmillSpinnerOverlay loading={posting} message="更新中" />
    </>
  );
}

function ShopSlotPatternSummary(props: { match }) {
  const { shops } = useContext(ShopsContext);
  const [shop, setShop] = useState<firebase.firestore.DocumentSnapshot>();
  const [slotPatterns, setSlotPatterns] = useState<any>();
  const [showArchived, setShowArchived] = useState(false);
  const [childShops, setChildShops] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [shopId, setShopId] = useState<string>();
  const [slotPattern, setSlotPattern] = useState<firebase.firestore.DocumentSnapshot>();

  const [slotPatternId, setSlotPatternId] = useQueryParam('slot_pattern_id', StringParam);

  useEffect(() => {
    setLoading(true);
    if (slotPatternId) {
      const unregisterShopObserver = firebase
        .firestore()
        .collection('slot_patterns')
        .doc(slotPatternId)
        .onSnapshot((doc) => {
          console.log('onSnapshot ShopSlotPatternSummary');
          if (doc.exists) {
            setSlotPattern(doc);
          }
          setLoading(false);
        });
      return () => {
        unregisterShopObserver();
      };
    }
    setSlotPattern(undefined);
    setLoading(false);
    return () => {};
  }, [slotPatternId]);

  useEffect(() => {
    const givenId = props.match.params.id;
    setShopId(givenId);

    const unregisterShopObserver = firebase
      .firestore()
      .collection('shops')
      .doc(givenId)
      .onSnapshot((doc) => {
        console.log('onSnapshot ShopSlotPatternSummary');
        if (doc.exists) {
          setShop(doc);
        }
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.match.params.id]);

  useEffect(() => {
    let query = firebase.firestore().collection('slot_patterns').where('shop_id', '==', props.match.params.id);

    if (!showArchived) {
      query = query.where('archived', '==', false);
    }

    const unregisterShopsObserver = query.onSnapshot((snap) => {
      console.log('onSnapshot ShopSlotPatternSummary');
      const records = {};
      snap.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        records[docSnapshot.id] = data;
      });

      setSlotPatterns(records);
    });

    return () => {
      unregisterShopsObserver();
    };
  }, [props.match.params.id, showArchived]);

  useEffect(() => {
    if (slotPattern && shopId) {
      const data = slotPattern.data()!;
      const sortedDeliveryGroups = Object.keys(data.delivery_groups)
        .map((deliveryGroupId: string) => {
          return { id: deliveryGroupId, data: data.delivery_groups[deliveryGroupId] };
        })
        .sort((a, b) => a.data.position - b.data.position);

      const sortShops = (a, b) => {
        if (a.id === shopId) {
          // 親店舗は先頭に
          return -1;
        }

        if (b.id === shopId) {
          // 親店舗は先頭に
          return 1;
        }

        if (!a.data.batch_delivery) {
          // デリバリー以外を前に (セルフレジ等)
          return -1;
        }

        if (!b.data.batch_delivery) {
          // デリバリー以外を前に (セルフレジ等)
          return 1;
        }

        const groupA = data.settings[a.id]?.delivery_group_id;
        const groupB = data.settings[b.id]?.delivery_group_id;

        let idxA = sortedDeliveryGroups.findIndex((group) => group.id === groupA);
        let idxB = sortedDeliveryGroups.findIndex((group) => group.id === groupB);

        if (idxA === -1) {
          idxA = Number.POSITIVE_INFINITY;
        }
        if (idxB === -1) {
          idxB = Number.POSITIVE_INFINITY;
        }

        if (idxA < idxB) {
          return -1;
        }
        if (idxA > idxB) {
          return 1;
        }

        const priorityA = data.settings[a.id]?.delivery_priority || Number.POSITIVE_INFINITY;
        const priorityB = data.settings[b.id]?.delivery_priority || Number.POSITIVE_INFINITY;

        if (priorityA < priorityB) {
          return -1;
        }
        if (priorityA > priorityB) {
          return 1;
        }

        return 0;
      };

      const unregisterShopsObserver = firebase
        .firestore()
        .collection('shops')
        .where('kitchen_shop_id', '==', shopId)
        .where('status', '==', 'active')
        .onSnapshot((snap) => {
          console.log('onSnapshot ShopSlotPatternSummary');
          const records: Array<{ id: string; data: any }> = [];
          records.push({ id: shopId, data: shops[shopId].data() });
          snap.forEach((docSnapshot) => {
            const shopData = docSnapshot.data();
            records.push({ id: docSnapshot.id, data: shopData });
          });

          records.sort(sortShops);
          setChildShops(records);
        });

      return () => {
        unregisterShopsObserver();
      };
    }
    return () => {};
  }, [shopId, shops, slotPattern]);

  const changeSlotPattern = (selected) => {
    setSlotPatternId(selected.target.value);
  };

  const createSlotPattern = async () => {
    const newPatternRef = firebase.firestore().collection('slot_patterns').doc();
    await newPatternRef.set({
      shop_id: shopId,
      label: '新規',
      archived: false,
      lines: [],
      shops: [
        {
          id: shopId,
          slots: [],
        },
      ],
      delivery_groups: {},
      settings: {},
    });

    setSlotPatternId(newPatternRef.id);
  };

  const copySlotPattern = async () => {
    const existPattern = await firebase.firestore().collection('slot_patterns').doc(slotPattern!.id).get();

    const newPatternRef = firebase.firestore().collection('slot_patterns').doc();
    await newPatternRef.set({
      ...existPattern.data(),
      label: `コピー ${existPattern.data()?.label}`,
      archived: false,
    });
    setSlotPatternId(newPatternRef.id);
  };

  const archiveSlotPattern = async () => {
    const today = dayjs().tz('Asia/Tokyo').format('YYYY-MM-DD');
    const assigned = await firebase
      .firestore()
      .collection('shops')
      .doc(props.match.params.id)
      .collection('assigned_slot_patterns')
      .where('slot_pattern_id', '==', slotPattern!.id)
      .where('date', '>=', today)
      .limit(1)
      .get();

    if (assigned.size > 0) {
      window.alert('今日以降で設定されているパターンは削除できません');
      return;
    }

    await firebase.firestore().collection('slot_patterns').doc(slotPattern!.id).update({ archived: true });

    setSlotPatternId('');
  };

  return (
    <div className="container-fluid h-100">
      {shop && slotPatterns && (
        <>
          <h3>
            {shop.data()!.short_name} (
            <NavLink to={`/admin/assign_slot_patterns/${props.match.params.id}`}>カレンダー設定</NavLink>)
          </h3>

          <div className="input-group">
            <select className="form-control form-control-sm" onChange={changeSlotPattern} value={slotPatternId ?? ''}>
              <option>パターンを選択してください</option>
              {Object.keys(slotPatterns).map((id) => (
                <option key={id} value={id}>
                  {slotPatterns[id].label}
                  {slotPatterns[id].archived ? ' (削除済)' : ''}
                </option>
              ))}
            </select>

            <button type="button" className="btn btn-secondary" onClick={createSlotPattern}>
              新規作成
            </button>
            {slotPattern && (
              <button type="button" className="btn btn-secondary" onClick={copySlotPattern}>
                コピー
              </button>
            )}
            {slotPattern && (
              <button type="button" className="btn btn-danger" onClick={archiveSlotPattern}>
                削除
              </button>
            )}
          </div>

          <div>
            <input
              type="checkbox"
              id="toggleArchived"
              onChange={(e) => {
                setShowArchived(e.target.checked);
              }}
            />
            <label htmlFor="toggleArchived">削除済も表示</label>
          </div>
        </>
      )}

      {shopId && shop && slotPattern && childShops && (
        <div key={slotPattern.id}>
          <SummaryForm shopId={shopId} slotPattern={slotPattern} childShops={childShops} />
          <ShopSlotPatternDetail shop={shop} slotPattern={slotPattern} childShops={childShops} />
        </div>
      )}

      <WindmillSpinnerOverlay loading={loading} message="読み込み中" />
    </div>
  );
}

export default ShopSlotPatternSummary;
