/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert';
import { StoreContainer } from './store';
import ShopsContext from './ShopsContext';

function SelfCheckoutPreference() {
  const { register, handleSubmit, setValue, reset, control } = useForm();
  const storeContainer = StoreContainer.useContainer();

  const { shops } = useContext(ShopsContext);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [selectedCheckoutShopId, setSelectedCheckoutShopId] = useState<string | undefined>();
  const [loadingSelfCheckoutPreference, setLoadingSelfCheckoutPreference] = useState<boolean>(false);
  const [salesComponents, setSalesComponents] = useState<Array<any>>([]);
  const [executing, setExecuting] = useState(false);
  const [uploadProgressQrCode, setUploadProgressQrCode] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'app_download_invitation.out_of_time_ranges',
  });

  const selfCheckoutShopIds = Object.keys(shops).filter(
    (shopId) =>
      shops[shopId].data()!.self_checkout_modes?.includes('self_checkout') &&
      (shops[shopId].data()?.kitchen_shop_id === storeContainer.shopId || shopId === storeContainer.shopId),
  );

  useEffect(() => {
    if (selectedCheckoutShopId && selfCheckoutShopIds.includes(selectedCheckoutShopId)) return;
    setSelectedCheckoutShopId(selfCheckoutShopIds.length > 0 ? selfCheckoutShopIds[0] : undefined);
  }, [selectedCheckoutShopId, selfCheckoutShopIds]);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedCheckoutShopId) {
          return;
        }

        setLoadingSelfCheckoutPreference(true);

        const snapshot = await firebase
          .firestore()
          .collection('self_checkout_preferences')
          .doc(selectedCheckoutShopId)
          .get();
        reset({
          ...snapshot.data(),
          active_payment_method: {
            square: snapshot.data()?.active_payment_method?.square ?? true,
            paypay: snapshot.data()?.active_payment_method?.paypay ?? true,
          },
          app_download_invitation: {
            ...snapshot.data()?.app_download_invitation,
            enabled: snapshot.data()?.app_download_invitation?.enabled ?? false,
            title: snapshot.data()?.app_download_invitation?.title ?? '',
            qr_code_url: snapshot.data()?.app_download_invitation?.qr_code_url ?? '',
            coupon_code: snapshot.data()?.app_download_invitation?.coupon_code ?? '',
          },
        });
      } catch (e) {
        alert(e);
      } finally {
        setLoadingSelfCheckoutPreference(false);
      }
    })();
  }, [selectedCheckoutShopId, reset]);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('sales_components')
      .orderBy('order')
      .onSnapshot((snap) => {
        console.log('onSnapshot SalesComponents');
        setSalesComponents(snap.docs.map((doc) => doc.data()!));
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  const handleChangeSelfCheckoutShopId = (event) => {
    setMessageSuccess('');
    setMessageError('');
    setSelectedCheckoutShopId(event.target.value);
  };

  const emptyDiv = () => <div />;
  const handleClickSubmitButton = (data) => {
    const options = {
      title: 'セルフレジの設定の更新を行います',
      message: '変更は直ちに反映されます',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const submit = async (argData) => {
    const data = { ...argData };
    try {
      if (!selectedCheckoutShopId) {
        return;
      }

      setExecuting(true);

      if (
        !data.app_download_invitation.title ||
        !data.app_download_invitation.qr_code_url ||
        !data.app_download_invitation.coupon_code
      ) {
        data.app_download_invitation.enabled = false;
      }
      if (data.active_payment_method.warning === '') {
        data.active_payment_method.warning = null;
      }

      await firebase
        .firestore()
        .collection('self_checkout_preferences')
        .doc(selectedCheckoutShopId)
        .set(data, { merge: true });

      setMessageSuccess('更新完了');
    } catch (e) {
      setMessageError(`更新に失敗しました${e}`);
      throw e;
    } finally {
      setExecuting(false);
    }
  };

  const handleUploadQRCode = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    let fileName;
    if (file.type === 'image/png') {
      fileName = `qr_code_${new Date().getTime()}.png`;
    } else if (file.type === 'image/jpeg') {
      fileName = `qr_code_${new Date().getTime()}.jpg`;
    } else {
      throw new Error("shouldn't come here");
    }

    const path = `/shops/${selectedCheckoutShopId}`;
    const fullPath = `${path}/${fileName}`;
    const uploadTask = firebase.storage().ref(fullPath).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgressQrCode(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setValue('app_download_invitation.qr_code_url', downloadURL);
        });
      },
    );
  };

  const progressMessageQrCode = () => {
    let msg: string;
    if (uploadProgressQrCode === 100) {
      msg = 'アップロード完了';
    } else if (uploadProgressQrCode > 0) {
      msg = `${uploadProgressQrCode}%`;
    } else {
      msg = '';
    }
    return msg;
  };
  const qrCodeUrl = useWatch({ control, name: 'app_download_invitation.qr_code_url' });
  const appDownloadInvitationEnabled = useWatch({ control, name: 'app_download_invitation.enabled' });
  return (
    <>
      <div className="container-fluid h-100">
        <h3>セルフレジの設定</h3>
        {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}
        {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

        {selfCheckoutShopIds.length === 0 ? (
          <div className="alert alert-warning">セルフレジを利用可能な店舗がありません</div>
        ) : (
          <form id="shop-form" onSubmit={handleSubmit(handleClickSubmitButton)}>
            <div className="form-group row">
              <div className="col-sm-10">
                <select onChange={(event) => handleChangeSelfCheckoutShopId(event)}>
                  {selfCheckoutShopIds.map((shopId) => (
                    <option key={shopId} value={shopId}>
                      {shops[shopId].data()!.short_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {selectedCheckoutShopId &&
              (loadingSelfCheckoutPreference ? (
                <div className="row">
                  <div className="col text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">カスタマイズを有効にする</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('enabled_customization', {})} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">強制的にカスタマイズを有効にするコンポーネント</label>
                    <div className="col-sm-8">
                      {salesComponents.length > 0 && (
                        <select
                          multiple
                          size={salesComponents.length}
                          {...register('forced_customization_components', {})}
                          style={{ maxWidth: '20em' }}
                        >
                          {salesComponents.map((salesComponent) => (
                            <option key={salesComponent.id} value={salesComponent.id}>
                              {salesComponent.name}
                            </option>
                          ))}
                        </select>
                      )}
                      <small className="form-text text-muted">
                        「強制的にカスタマイズを有効にするコンポーネント」を指定すると「カスタマイズを有効にする」のON/OFFに関わらず、指定したコンポーネントのカスタマイズが有効になります。
                      </small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">カロリー/糖質/PFCを表示する</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('show_spec', {})} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">クーポン利用を有効にする</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('enabled_coupon', {})} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ニックネーム入力を有効にする</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('enabled_nickname', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">アプリ招待QRコード</label>

                    <div className="col-sm-8">
                      <label className="mr-2">
                        アプリ招待QRコードを表示する:
                        <input type="checkbox" size={20} {...register('app_download_invitation.enabled', {})} />
                      </label>
                      <br />
                      <input
                        disabled={!appDownloadInvitationEnabled}
                        type="text"
                        size={80}
                        {...register('app_download_invitation.title', {})}
                      />
                      <small className="form-text text-muted mb-3">QRコードのタイトル</small>

                      <input
                        disabled={!appDownloadInvitationEnabled}
                        type="text"
                        size={80}
                        {...register('app_download_invitation.qr_code_url', {})}
                      />
                      <small className="form-text text-muted mb-3">QRコード画像URL</small>

                      <input
                        disabled={!appDownloadInvitationEnabled}
                        type="text"
                        size={20}
                        {...register('app_download_invitation.coupon_code', {})}
                      />
                      <small className="form-text text-muted mb-3">クーポンコード</small>

                      {qrCodeUrl && (
                        <>
                          <img src={qrCodeUrl} alt="QR code" style={{ width: '240px' }} />
                          <button
                            disabled={!appDownloadInvitationEnabled}
                            type="button"
                            onClick={() => {
                              setValue('app_download_invitation.qr_code_url', null);
                            }}
                          >
                            削除
                          </button>
                        </>
                      )}

                      <div className="mt-3">
                        <label htmlFor="qrCodeImageUpload">
                          <input
                            disabled={!appDownloadInvitationEnabled}
                            id="qrCodeImageUpload"
                            type="file"
                            name="file"
                            className="form-control-file"
                            accept="image/jpeg, image/png"
                            onChange={handleUploadQRCode}
                          />
                        </label>
                        {progressMessageQrCode()}
                        <div>
                          非表示にする時間帯
                          <div>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                append({
                                  start_time: '',
                                  end_time: '',
                                })
                              }
                            >
                              追加
                            </button>
                          </div>
                          {fields.map((field, index) => (
                            <div className="row align-items-center" key={field.id}>
                              <div className="col">
                                <label className="form-label">開始時間</label>
                                <input
                                  className="form-control"
                                  {...register(`app_download_invitation.out_of_time_ranges.${index}.start_time`, {
                                    required: true,
                                  })}
                                  type="time"
                                />
                              </div>

                              <div className="col">
                                <label className="form-label">終了時間</label>
                                <input
                                  className="form-control"
                                  {...register(`app_download_invitation.out_of_time_ranges.${index}.end_time`, {
                                    required: true,
                                  })}
                                  type="time"
                                />
                              </div>
                              <div className="col">
                                <button type="button" className="btn btn-danger" onClick={() => remove(index)}>
                                  削除
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">有効な決済方法</label>

                    <div className="col-sm-8">
                      <label className="mr-2">
                        Square:
                        <input type="checkbox" size={20} {...register('active_payment_method.square', {})} />
                      </label>
                      <label>
                        PayPay:
                        <input type="checkbox" size={20} {...register('active_payment_method.paypay', {})} />
                      </label>
                      <br />
                      <input type="text" size={80} {...register('active_payment_method.warning', {})} />
                      <small className="form-text text-muted mb-3">決済手段選択画面に出す注意文言</small>
                    </div>
                  </div>

                  <input type="submit" className="btn btn-primary" value="更新" />
                </>
              ))}
          </form>
        )}
      </div>
      <WindmillSpinnerOverlay loading={executing} message="更新中" />
    </>
  );
}

export default SelfCheckoutPreference;
