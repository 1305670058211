import React, { useContext, useEffect, useState } from 'react';
import firebase from './firebase';
import { StoreContainer } from './store';
import useKitchenLayout from './hooks/useKitchenLayout';
import CurrentShelves from './CurrentShelves';
import ShopsContext from './ShopsContext';
import Order from './Order';
import Utils from './Utils';

function CookedOrders() {
  const storeContainer = StoreContainer.useContainer();
  const { currentShop } = useContext(ShopsContext);

  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const kitchenLayoutSnapshot = useKitchenLayout();

  if (currentShop?.data()!.cooking_lines === 1 && Utils.isNumber(storeContainer.cookedLine)) {
    // 2ライン以上から1ラインに戻ったときにallにする
    storeContainer.setCookedLine('all');
  }

  if (!storeContainer.cookedLine) {
    storeContainer.setCookedLine('all');
  }
  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('status', 'in', ['cooked', 'received'])
      .where('cooked_at', '>=', new Date(new Date().getTime() - 2 * 60 * 60 * 1000));
    // show only last 2 hours cooked orders

    if (storeContainer.cookedLine === 'pickup_now') {
      query = query.where('pickup_now', '==', true);
    } else if (storeContainer.cookedLine === 'slot_order') {
      query = query.where('pickup_now', '==', false);
    } else if (storeContainer.cookedLine === 'all') {
      // no filter
    } else if (storeContainer.cookedLine) {
      query = query.where('line2', '==', storeContainer.cookedLine);
    }

    const unregisterOrdersObserver = query.orderBy('cooked_at', 'desc').onSnapshot((snap) => {
      console.log('onSnapshot CookedOrders');

      setOrders(
        snap.docs.filter(
          (doc) =>
            // 即時注文は all と pickup_now 以外では表示しない
            storeContainer.cookedLine === 'all' || storeContainer.cookedLine === 'pickup_now' || !doc.data().pickup_now,
        ),
      );
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, storeContainer.cookedLine, currentShop]);

  return (
    <div id="cooked-orders" className="container-fluid">
      <div className="scrollable-orders">
        {orders === undefined ? (
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-2">
                <select
                  className="form-control"
                  onChange={(event) => {
                    if (event.target.value === 'pickup_now') {
                      storeContainer.setCookedLine('pickup_now');
                    } else if (event.target.value === 'slot_order') {
                      storeContainer.setCookedLine('slot_order');
                    } else if (event.target.value === 'all') {
                      storeContainer.setCookedLine('all');
                    }
                  }}
                  defaultValue={storeContainer.cookedLine ? storeContainer.cookedLine.toString() : ''}
                >
                  <option value="all">全て</option>
                  <option value="slot_order">予約注文</option>
                  {currentShop?.data()!.cooking_lines > 1 &&
                    Array(currentShop?.data()!.cooking_lines)
                      .fill('dummy')
                      .map((_, i) => <option value={`${i + 1}`}>ライン{i + 1}</option>)}
                  <option value="pickup_now">即時注文</option>
                </select>
              </div>
              {currentShop?.data()!.shelf_layout && (
                <div className="col-2">
                  <CurrentShelves />
                </div>
              )}
            </div>
            {Object.keys(orders).length === 0 ? (
              <div className="row">
                <div className="col">
                  <div className="alert alert-light text-center" role="alert">
                    ありません
                  </div>
                </div>
              </div>
            ) : (
              orders.map((tmp) => (
                <Order
                  key={tmp.id}
                  kitchenOrderId={tmp.id}
                  order={tmp.data()}
                  line={null}
                  kitchenLayoutSnapshot={kitchenLayoutSnapshot}
                  hideWhenDone
                />
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CookedOrders;
