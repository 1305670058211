import React, { useEffect, useState, useContext } from 'react';

import { NavLink } from 'react-router-dom';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import firebase from './firebase';
import 'firebase/compat/auth';

import CustomerFeedback from './CustomerFeedback';
import CustomerPasses from './CustomerPasses';
import CouponLink from './CouponLink';
import CustomerFriends from './CustomerFriends';
import Utils from './Utils';
import OrderRefund from './OrderRefund';
import CustomerUpdate from './CustomerUpdate';
import CustomerPrivateUpdate from './CustomerPrivateUpdate';
import CsRequestsUpdate from './CsRequestsUpdate';
import PaymentType from './PaymentType';
import RoleContext from './RoleContext';
import useCustomerInfo from './useCustomerInfo';
import CustomerComplaint from './CustomerComplaint';
import Pass from './Pass';
import { StoreContainer } from './store';
import ShopsContext from './ShopsContext';

function Customer(props: { match }) {
  const [customer, setCustomer] = useState<firebase.firestore.DocumentData>();
  const [customerPrivate, setCustomerPrivate] = useState<firebase.firestore.DocumentData>();
  const [passIds, setPassIds] = useState<Array<string>>();
  const [orders, setOrders] = useState({});
  const [messages, setMessages] = useState({});
  const [unsentMessages, setUnsentMessages] = useState({});
  const [expiredMessages, setExpiredMessages] = useState({});
  const [updating, setUpdating] = useState(false);
  const [refundModalIsOpen, setRefundModalIsOpen] = useState(false);
  const [refundingOrder, setRefundingOrder] = useState(null);
  const [refundingOrderId, setRefundingOrderId] = useState('');
  const role = useContext(RoleContext);
  const [customerUpdateModalIsOpen, setCustomerUpdateModalIsOpen] = useState(false);
  const [customerUpdateTarget, setCustomerUpdateTarget] = useState<'email' | 'phoneNumber'>('email');
  const [customerPrivateModalIsOpen, setCustomerPrivateModalIsOpen] = useState(false);
  const [csRequestsModalIsOpen, setCsRequestsModalIsOpen] = useState(false);
  const [coupons, setCoupons] =
    useState<
      Array<{ name: string; code: string; terms: string; expiresAt: Date; count: number; limitPerCustomer: number }>
    >();

  const { customerInfo, forceUpdate } = useCustomerInfo(props.match.params.id);

  const { shops } = useContext(ShopsContext);

  useEffect(() => {
    firebase
      .firestore()
      .collection('customers')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        setCustomer(doc.data());
      });

    firebase
      .firestore()
      .collection('customers_private')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCustomerPrivate(doc.data());
        }
      });

    firebase
      .firestore()
      .collection('pass_orders')
      .where('customer_id', '==', props.match.params.id)
      .where('status', '==', 'active')
      .get()
      .then((snapshot) => {
        setPassIds(snapshot.docs.map((doc) => doc.data().pass_id));
      });

    firebase
      .firestore()
      .collection('orders')
      .where('customer_id', '==', props.match.params.id)
      .orderBy('ordered_at', 'desc')
      .get()
      .then((docSnapshot) => {
        const records2 = [];
        docSnapshot.forEach((docSnapshot2) => {
          const orderData = docSnapshot2.data();
          records2[docSnapshot2.id] = orderData;
          records2[docSnapshot2.id].shop_name = shops[orderData.shop_id].data()!.short_name;
        });
        setOrders(records2);
      });

    firebase
      .firestore()
      .collection('customers')
      .doc(props.match.params.id)
      .collection('messages')
      .orderBy('sent_at', 'desc')
      .get()
      .then((docSnapshot) => {
        const msgs = [];
        docSnapshot.forEach((docSnapshot3) => {
          msgs[docSnapshot3.id] = docSnapshot3.data();
        });
        setMessages(msgs);
      });

    firebase
      .firestore()
      .collection('customers')
      .doc(props.match.params.id)
      .collection('unsent_messages')
      .orderBy('created_at', 'desc')
      .get()
      .then((docSnapshot) => {
        console.log('onSnapshot unsent messages');
        const msgs = [];
        docSnapshot.forEach((docSnapshot3) => {
          msgs[docSnapshot3.id] = docSnapshot3.data();
        });
        setUnsentMessages(msgs);
      });

    firebase
      .firestore()
      .collection('expired_messages')
      .where('customer_id', '==', props.match.params.id)
      .orderBy('sent_at', 'desc')
      .get()
      .then((docSnapshot) => {
        const records = [];
        docSnapshot.forEach((docSnapshot4) => {
          records[docSnapshot4.id] = docSnapshot4.data();
        });
        setExpiredMessages(records);
      });

    return () => {};
  }, [props.match.params.id, shops]);

  useEffect(() => {
    const auth = firebase.auth();
    const apiEndPoint = `${process.env.REACT_APP_api_server}/coupons?customer_id=${props.match.params.id}`;
    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
        const responseJson = await response.json();
        if (response.status === 200) {
          const result = responseJson.coupons.map((coupon) => {
            return {
              name: coupon.name,
              code: coupon.code,
              terms: coupon.terms_of_use,
              expiresAt: new Date(coupon.end_period * 1000),
              count: coupon.count || 0,
              limitPerCustomer: coupon.limit_per_customer ?? null,
            };
          });
          setCoupons(result);
        }
      });
    });
  }, [props.match.params.id]);

  const progressRefund = (start: boolean) => {
    setUpdating(start);
    if (!start) {
      setRefundModalIsOpen(false);
    }
  };

  const progressUpdateCustomer = (start: boolean) => {
    setUpdating(start);
    if (!start) {
      forceUpdate();
      setCustomerUpdateModalIsOpen(false);
    }
  };

  const progressUpdateCustomerPrivate = (start: boolean) => {
    setUpdating(start);
    if (!start) {
      setCustomerPrivateModalIsOpen(false);
    }
  };

  const progressUpdateCsRequestrs = (start: boolean) => {
    setUpdating(start);
    if (!start) {
      setCsRequestsModalIsOpen(false);
    }
  };

  const appVersion = (order) => {
    const version: Array<React.ReactElement> = [];

    if (order.os === 'IOS') {
      version.push(<i className="fab fa-apple" />);
    } else if (order.os === 'ANDROID') {
      version.push(<i className="fab fa-android" />);
    }

    version.push(
      <span>
        {order.version}
        <br />
        {order.device_id ? order.device_id.substr(0, 6) : ''}
      </span>,
    );

    return version;
  };

  return (
    <>
      <div id="customer" className="container-fluid h-80">
        <div className="scrollable-customer h-100">
          {customer === undefined ? (
            <div className="row">
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {customer === null ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-light text-center" role="alert">
                      ありません
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <form>
                    <div className="row my-1">
                      <div className="col-sm-4">ニックネーム</div>
                      <div className="col">
                        {customer.deleted_at ? (
                          <span>
                            退会済(
                            {dayjs(customer.deleted_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')})
                          </span>
                        ) : (
                          <>
                            {customer.nickname}
                            {customerInfo && customerInfo.churnStatus === 'WILL_CHURN' && (
                              <span style={{ color: 'red' }}>
                                &nbsp;
                                <i className="fas fa-exclamation-triangle fa-xs" />
                              </span>
                            )}
                            &nbsp;
                            {role.isCs() && (
                              <NavLink
                                to={`/customer_message/${props.match.params.id}`}
                                className=""
                                activeClassName="active"
                              >
                                (メッセージ送信)
                              </NavLink>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {role.isCs() && (
                      <>
                        <div className="row my-1">
                          <div className="col-sm-4">メールアドレス</div>
                          <div className="col">
                            <div className="row">
                              <div className="col-auto">
                                {!customerInfo && 'loading...'}
                                {customerInfo && (
                                  <>
                                    {customerInfo.email ? (
                                      <a href={`mailto:${customerInfo.email}`}>{customerInfo.email}</a>
                                    ) : (
                                      '(設定なし)'
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="col-auto">
                                {role.isCs() && (
                                  <>
                                    &nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-light"
                                      onClick={() => {
                                        setCustomerUpdateTarget('email');
                                        setCustomerUpdateModalIsOpen(true);
                                      }}
                                    >
                                      更新
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row my-1">
                          <div className="col-sm-4">電話番号</div>
                          <div className="col">
                            <div className="row">
                              <div className="col-auto">
                                {!customerInfo && 'loading...'}
                                {customerInfo && (
                                  <>
                                    {customerInfo.phoneNumber ? (
                                      <a href={`tel:${customerInfo.phoneNumber}`}>{customerInfo.phoneNumber}</a>
                                    ) : (
                                      '(設定なし)'
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="col-auto">
                                {role.isCs() && (
                                  <>
                                    &nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-light"
                                      onClick={() => {
                                        setCustomerUpdateTarget('phoneNumber');
                                        setCustomerUpdateModalIsOpen(true);
                                      }}
                                    >
                                      更新
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row my-1">
                          <div className="col-sm-4">注文時電話番号</div>
                          <div className="col">{customer && customer.tel ? customer.tel : '(設定なし)'}</div>
                        </div>
                      </>
                    )}
                    <div className="row my-1">
                      <div className="col-sm-4">友達紹介クーポン</div>
                      <div className="col">
                        {customer && customer.friends_invitation ? customer.friends_invitation.coupon_code : '-'}
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-sm-4">パス</div>
                      <div className="col">
                        {!passIds && 'loading...'}
                        {passIds && (
                          <>
                            {passIds.length === 0
                              ? '(なし)'
                              : passIds
                                  .map<React.ReactNode>((passId) => <Pass passId={passId} />)
                                  .reduce((prev, curr) => [prev, ', ', curr])}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-sm-4">CS要望</div>
                      <div className="col">
                        <div className="row">
                          {customerPrivate && customerPrivate.cs_requests?.length > 0 ? (
                            <div className="col">
                              <ul className="list-group">
                                {customerPrivate.cs_requests.map((request) => (
                                  <li className="list-group-item d-flex justify-content-between align-items-center">
                                    {request.request}
                                    <span className="badge badge-dark badge-pill ml-1">
                                      {request.repeat ? '繰り返し' : '一回のみ'}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : (
                            <div className="col-auto">(設定なし)</div>
                          )}
                          <div className="col-auto">
                            {role.isCs() && (
                              <>
                                &nbsp;
                                <button
                                  type="button"
                                  className="btn btn-sm btn-light"
                                  onClick={() => {
                                    setCsRequestsModalIsOpen(true);
                                  }}
                                >
                                  更新
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                    <div className="row my-2">
                      <div className="col-sm-4">お好み</div>
                      <div className="col">
                        {role.isCs() && (
                          <>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-sm btn-light"
                              onClick={() => {
                                setCustomerPrivateModalIsOpen(true);
                              }}
                            >
                              更新
                            </button>
                          </>
                        )}
                        <ul>
                          {customerPrivate && customerPrivate?.favorite_toppings && (
                            <li>好きなトッピング: {customerPrivate?.favorite_toppings}</li>
                          )}
                          {customerPrivate && customerPrivate?.disliked_toppings && (
                            <li>苦手なトッピング: {customerPrivate?.disliked_toppings}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-sm-4">作成日</div>
                      <div className="col">
                        {customer.created_at
                          ? dayjs(customer.created_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
                          : ''}
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-sm-4">メッセージグループ</div>
                      <div className="col">{customer.message_group}</div>
                    </div>
                  </form>

                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h4>ご意見・ご感想</h4>
                      <CustomerFeedback customerId={props.match.params.id} />
                    </div>
                    <div className="col-sm-6">
                      <h4>商品不備の報告</h4>
                      <CustomerComplaint customerId={props.match.params.id} showImage />
                    </div>
                  </div>

                  <hr />
                  <h4>クーポン</h4>

                  <div className="container">
                    <div className="row">
                      {coupons &&
                        (coupons.length > 0
                          ? coupons.map((coupon) => (
                              <div className="card mx-2 mb-4" style={{ width: '14rem' }}>
                                <div className="card-body">
                                  <h5 className="card-title">{coupon.name}</h5>
                                  <p className="card-text">
                                    <small>
                                      コード: {coupon.code}
                                      <br />
                                      有効期限: {dayjs(coupon.expiresAt).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                                      <br />
                                      利用回数: {coupon.count}
                                      {coupon.limitPerCustomer &&
                                        coupon.limitPerCustomer > 0 &&
                                        ` / ${coupon.limitPerCustomer}`}
                                    </small>
                                  </p>
                                  <p className="card-text">
                                    <pre>{coupon.terms}</pre>
                                  </p>
                                </div>
                              </div>
                            ))
                          : 'なし')}
                    </div>
                  </div>

                  <hr />
                  <h4>オーダー履歴</h4>
                  <table className="table w-auto text-right">
                    <thead>
                      <tr>
                        <th> </th>
                        <th>店舗</th>
                        <th>離脱注意</th>
                        <th>注文日時</th>
                        <th>キャンセル日時</th>
                        <th>スロット日時</th>
                        <th>調理完了予定日時</th>
                        <th>調理完了日時</th>
                        <th>お渡し予定日時</th>
                        <th>お渡し完了日時</th>
                        <th>注文番号</th>
                        <th>支払い方法</th>
                        <th>支払い金額</th>
                        <th>支払い日時</th>
                        <th>返金日時(額)</th>
                        <th>ステータス</th>
                        <th>クーポン</th>
                        <th>備考</th>
                        <th>アプリ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(orders).map((orderId) => (
                        <tr key={orderId}>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Utils.isRefundableOrder(orders[orderId]) && role.isCs() && (
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  setRefundingOrder(orders[orderId]);
                                  setRefundingOrderId(orderId);
                                  setRefundModalIsOpen(true);
                                }}
                              >
                                返金
                              </button>
                            )}
                            {orders[orderId].refunded_at && role.isCs() && <span>返金済</span>}
                          </td>
                          <td>{orders[orderId].shop_name}</td>
                          <td>
                            {orders[orderId].churn_status === 'WILL_CHURN' && (
                              <span style={{ color: 'red' }}>
                                <i className="fas fa-exclamation-triangle fa-xs" />
                              </span>
                            )}
                          </td>
                          <td>
                            {dayjs(orders[orderId].ordered_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].canceled_at
                              ? dayjs(orders[orderId].canceled_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {dayjs(orders[orderId].pickup_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].cooking_slots && orders[orderId].cooking_slots.length > 0
                              ? dayjs(
                                  orders[orderId].cooking_slots[
                                    orders[orderId].cooking_slots.length - 1
                                  ].time_until.toDate(),
                                )
                                  .tz('Asia/Tokyo')
                                  .format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].cooked_at
                              ? dayjs(orders[orderId].cooked_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].estimated_at || orders[orderId].pickup_until_at
                              ? dayjs((orders[orderId].estimated_at || orders[orderId].pickup_until_at).toDate())
                                  .tz('Asia/Tokyo')
                                  .format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].done_by_staff_at
                              ? dayjs(orders[orderId].done_by_staff_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td>
                            <NavLink key={orderId} to={`/orders/${orderId}`}>
                              {Utils.formatNumber(orders[orderId])}
                            </NavLink>
                          </td>
                          <td>
                            <PaymentType order={orders[orderId]} />
                          </td>
                          <td>
                            &yen;
                            {orders[orderId].amount}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].paid_at
                              ? dayjs(orders[orderId].paid_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')
                              : '-'}
                            {orders[orderId].square_canceled_reason ? (
                              <span>
                                <br />
                                決済未完了 ({orders[orderId].square_canceled_reason})
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].refunded_at ? (
                              <>
                                {dayjs(orders[orderId].refunded_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}
                                <br />
                                {orders[orderId].refunded_amount} 円
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>{orders[orderId].status}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {orders[orderId].coupon_code && <CouponLink code={orders[orderId].coupon_code} />}
                          </td>
                          <td>{orders[orderId].request}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>{appVersion(orders[orderId])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <hr />
                  <h4>パス購入履歴</h4>
                  <CustomerPasses customerId={props.match.params.id} />

                  <hr />
                  <h4>
                    紹介した友達
                    <small>
                      (紹介クーポン:{' '}
                      {customer && customer.friends_invitation ? customer.friends_invitation.coupon_code : '-'})
                    </small>
                  </h4>
                  <div className="row">
                    <div className="col-sm-5">
                      <CustomerFriends customerId={props.match.params.id} />
                    </div>
                  </div>

                  <hr />
                  <h4>メッセージ</h4>

                  <table className="table text-right">
                    <thead>
                      <tr>
                        <th>送信日時</th>
                        <th>読了日時</th>
                        <th>タイトル</th>
                        <th>本文</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(messages).map((messageId) => (
                        <tr key={messageId}>
                          <td>{dayjs(messages[messageId].sent_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
                          <td>
                            {messages[messageId].read_at
                              ? dayjs(messages[messageId].read_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td>{messages[messageId].subject}</td>
                          <td className="text-left">
                            <pre>{messages[messageId].body}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <hr />
                  <h4>除外されたメッセージ</h4>

                  <table className="table text-right">
                    <thead>
                      <tr>
                        <th>送信予定日時</th>
                        <th>理由</th>
                        <th>タイトル</th>
                        <th>本文</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(unsentMessages).map((messageId) => (
                        <tr key={messageId}>
                          <td>
                            {dayjs(unsentMessages[messageId].created_at.toDate())
                              .tz('Asia/Tokyo')
                              .format('MM/DD HH:mm')}
                          </td>
                          <td>{unsentMessages[messageId].reason}</td>
                          <td>{unsentMessages[messageId].subject}</td>
                          <td className="text-left">
                            <pre>{unsentMessages[messageId].body}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <hr />
                  <h4>削除済メッセージ</h4>
                  <table className="table text-right">
                    <thead>
                      <tr>
                        <th>送信日時</th>
                        <th>読了日時</th>
                        <th>タイトル</th>
                        <th>本文</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(expiredMessages).map((expiredMessageId) => (
                        <tr key={expiredMessageId}>
                          <td>
                            {dayjs(expiredMessages[expiredMessageId].sent_at.toDate())
                              .tz('Asia/Tokyo')
                              .format('MM/DD HH:mm')}
                          </td>
                          <td>
                            {expiredMessages[expiredMessageId].read_at
                              ? dayjs(expiredMessages[expiredMessageId].read_at.toDate())
                                  .tz('Asia/Tokyo')
                                  .format('MM/DD HH:mm')
                              : '-'}
                          </td>
                          <td>{expiredMessages[expiredMessageId].subject}</td>
                          <td className="text-left">
                            <pre>{expiredMessages[expiredMessageId].body}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {role.isCs() && (
        <OrderRefund
          orderId={refundingOrderId}
          order={refundingOrder}
          modalIsOpen={refundModalIsOpen}
          inProgress={progressRefund}
        />
      )}

      {role.isCs() && customerInfo && (
        <>
          <CustomerUpdate
            customerId={props.match.params.id}
            email={customerInfo.email || ''}
            phoneNumber={customerInfo.phoneNumber || ''}
            modalIsOpen={customerUpdateModalIsOpen}
            inProgress={progressUpdateCustomer}
            target={customerUpdateTarget}
          />

          <CustomerPrivateUpdate
            customerId={props.match.params.id}
            modalIsOpen={customerPrivateModalIsOpen}
            inProgress={progressUpdateCustomerPrivate}
            favoriteToppings={customerPrivate?.favorite_toppings || ''}
            dislikedToppings={customerPrivate?.disliked_toppings || ''}
          />

          <CsRequestsUpdate
            customerId={props.match.params.id}
            modalIsOpen={csRequestsModalIsOpen}
            inProgress={progressUpdateCsRequestrs}
          />
        </>
      )}

      <WindmillSpinnerOverlay loading={updating} message="更新中" />
    </>
  );
}

export default Customer;
