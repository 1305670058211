import React, { useEffect, useState } from 'react';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';

function TodaySuccessRate() {
  const storeContainer = StoreContainer.useContainer();
  const [message, setMessage] = useState('');
  const [today, setToday] = useState(dayjs().tz('Asia/Tokyo').startOf('day').toDate());

  useEffect(() => {
    const interval = setInterval(() => {
      setToday(dayjs().tz('Asia/Tokyo').startOf('day').toDate());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('cooked_at', '>=', today)
      .onSnapshot((snap) => {
        console.log('onSnapshot TodaySuccessRate');
        const allowMinutesBefore = 5;

        let ok = 0;
        let total = 0;
        for (const record of snap.docs) {
          const data = record.data();
          if (!data.estimated_at || data.delivery_departure_due_time) {
            continue;
          }
          total += 1;
          if (
            new Date(+data.estimated_at.toDate() - allowMinutesBefore * 60 * 1000) < data.cooked_at.toDate() &&
            data.cooked_at.toDate() < data.estimated_at.toDate()
          ) {
            ok += 1;
          }
        }

        const percent = total > 0 ? Math.ceil((ok / total) * 100) : '-';

        setMessage(`本日時間内オンタイム率 - ${percent}% (${ok}/${total})`);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [today, storeContainer.shopId]);

  return <span className="badge badge-info">{message}</span>;
}

export default TodaySuccessRate;
