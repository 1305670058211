import firebase from './firebase';

async function addLabelPrinterJob({
  type,
  shopId,
  ordersForKitchenId = null,
  elementItemId = null,
}: {
  type: 'order' | 'expiration';
  shopId: string;
  ordersForKitchenId?: string | null;
  elementItemId?: string | null;
}) {
  const apiEndPoint = `${process.env.REACT_APP_api_server}/add_label_printer_job`;
  const auth = firebase.auth();

  await auth.currentUser!.getIdToken().then(async (token) => {
    await fetch(apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        type,
        shop_id: shopId,
        orders_for_kitchen_id: ordersForKitchenId,
        element_item_id: elementItemId,
      }),
    }).then(async (response) => {
      if (response.status !== 200) {
        const responseJson = await response.json();
        if (responseJson.error?.message) {
          window.alert(responseJson.error?.message);
        }
      }
    });
  });
}

export default addLabelPrinterJob;
